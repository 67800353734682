import React from 'react'
import { MDXTag } from '@mdx-js/tag'


const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = null;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" components={components}><MDXTag name="p" components={components}>{`What is nice about using WSL is that you can get an isolated Linux environment similar to containers on your Windows machine, which unlocks a couple of opportunities:`}</MDXTag>
      <MDXTag name="ul" components={components}>
        <MDXTag name="li" components={components} parentName="ul">{`With `}<MDXTag name="a" components={components} parentName="li" props={{
            "href": "https://youtu.be/b2mnbyRgXkY?t=7975"
          }}>{`WSLG`}</MDXTag>{` you will be able to run Linux UI applications in such a manner that you'll not even be able to tell a difference between Windows UI application, and the one you launched through WSLG. You can even `}<MDXTag name="a" components={components} parentName="li" props={{
            "href": "https://techcommunity.microsoft.com/t5/windows-dev-appconsult/running-wsl-gui-apps-on-windows-10/ba-p/1493242"
          }}>{`do it today`}</MDXTag>{`, but, based on the video, I think experience will be much better. Here is an example of how to `}<MDXTag name="a" components={components} parentName="li" props={{
            "href": "https://www.tomaszmik.us/2020/01/26/intellij-on-wsl/"
          }}>{`run code editor from WSL`}</MDXTag>{` to overcome various performance & UX issues when working with containers (note, same example can be used for other UI apps)`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`In comparison to VMs, WSL distributions are smaller & much faster to start working with. It doesn't feel as annoying to toggle between multiple operating systems. Microsoft picked Ubuntu as an OS of choice for promoting Linux through Windows, and if you look at hyper-v support of LTS Ubuntu version compared to WSL support, you'll see that focus certainly shifted in favor of WSL`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`In comparison to containers, WSL doesn't force you to have a single service to be running per a distro. It gives you persistent file system that you can easily access from Windows out of the box. You can keep using familiar Windows environment, shortcuts, code editors that are integrated with WSL, while still working with Linux based environment. However, it doesn't mean that WSL is a replacement for containers for cases when you want even smaller & isolated environments (or have a need for it, for example you don't want to mess your npm dependencies in one project by forgetting to switch to a specific version of node, because you were working on another project). It is not uncommon to see WSL used side by side with containers (Docker's integration with WSL2)`}</MDXTag>
      </MDXTag>
      <MDXTag name="p" components={components}>{`It may sound like WSL is awesome, but truth is that is has its problems. Below you can find a list of problems I run into, and some workaround to get cope with them:`}</MDXTag>
      <MDXTag name="ul" components={components}>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
            "href": "https://github.com/microsoft/WSL/issues/4166"
          }}>{`WSL2 can consume a lot of memory`}</MDXTag>{`. You can set limit for it in config file`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
            "href": "https://github.com/microsoft/WSL/issues/4197"
          }}>{`File system performance issues`}</MDXTag>{`. Workaround is to work only specifically with linux filesystem by taking advantage of editors that support WSL filesystem or use WSL UI applications`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
            "href": "https://github.com/Microsoft/WSL/issues/1350"
          }}>{`VPN running on host machine`}</MDXTag>{` may cause dns issues. Solution is to generate `}<MDXTag name="inlineCode" components={components} parentName="li">{`/etc/resolv.conf`}</MDXTag>{` file that uses the same DNS as your host machine`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`Once I run into a problem where `}<MDXTag name="a" components={components} parentName="li" props={{
            "href": "https://github.com/microsoft/WSL/issues/4364"
          }}>{`WSL was failing to start`}</MDXTag>{`. I had to stop Docker Desktop and Docker Desktop Service to get WSL back to running state`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`In case Docker restart takes a lot of time after you restarted WSL via `}<MDXTag name="inlineCode" components={components} parentName="li">{`Restart-Service LxssManager`}</MDXTag>{` - it is best to quit, then start Docker`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`It is a bit annoying that you can't run multiple instances of the same distro out of the box. For example Ubuntu is the best distro to start working with as it comes with a lot of tools preinstalled (while on Debian you need to install even basic commands like `}<MDXTag name="inlineCode" components={components} parentName="li">{`curl`}</MDXTag>{`, `}<MDXTag name="inlineCode" components={components} parentName="li">{`wget`}</MDXTag>{` yourself), but it is easy to only install first instance of Ubuntu. If you want to install more than one instance you need to copy some files around, run more commands than you would expect`}</MDXTag>
      </MDXTag>
      <MDXTag name="h2" components={components}>{`Security`}</MDXTag>
      <MDXTag name="p" components={components}>{`TL;DR from `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://securityboulevard.com/2020/09/running-sensitive-apps-in-wsl-safe-safe-safe/"
        }}>{`https://securityboulevard.com/2020/09/running-sensitive-apps-in-wsl-safe-safe-safe/`}</MDXTag>{`:`}</MDXTag>
      <MDXTag name="ul" components={components}>
        <MDXTag name="li" components={components} parentName="ul">{`WSL is a Windows utility that allows users to run Linux applications under Windows`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`Any standard (non-admin) Windows process has full access rights to all the files that make up the WSL machine `}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`If a malicious program runs as this standard process, it can steal sensitive static data (e.g., SSH keys) by simply copying them from the WSL file system`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`By modifying the programs in the WSL file system, our malicious program can also capture sensitive dynamic data (e.g., usernames, passwords, passphrases)`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`The WSL design allows the activation of Windows processes by programs running inside the Linux machine. Therefore, a standard (non-root) Linux program can completely take over the Linux machine `}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`WSL 2, designed as a “lightweight Utility VM”, has markedly diminished the attack surfaces of WSL, but is still vulnerable to the security weakness described here`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`Bottom line: Running sensitive applications inside WSL is significantly less secure than running the equivalent applications in a standalone Windows or Linux Desktop system`}</MDXTag>
      </MDXTag>
      <MDXTag name="p" components={components}>{`I'll not cover security workarounds you can apply, but there are definitely some options ;)`}</MDXTag>
      <MDXTag name="p" components={components}>{`Thank you for reading!`}</MDXTag>
           </MDXTag>;
  }

}
export const _frontmatter = {
  "title": "My experience using WSL (Windows Subsystem for Linux)",
  "date": "2021-01-31T12:00:00.000Z",
  "overview": "Purpose of WSL, issues, tips & tricks that I learned while working with it",
  "type": "post"
};
    